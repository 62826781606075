.stations {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  width: 100%;
}

.station-block {
  width: calc(20% - 10px);
  border-radius: 10px;
  margin-top: 10px;
  background: #fff;
  position: relative;
  inset: 0;
  transition: box-shadow .2s;
  box-shadow: 0 0 0 0 rgba(64, 72, 98, 0);
  padding: 20px 24px;
  box-sizing: border-box;
  font-size: 12px;

  &-icons {
    position: absolute;
    right: 14px;
    top: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-icon {
    width: 17px;
    border-radius: 4px;
    height: 17px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E6E9F2;
    margin-left: 4px;

    &:hover {
      opacity: 7;
    }

  }

  &-name {
    display: block;
    font-weight: 500;
    padding-right: 35px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.2;
    color: #333338;
    overflow: hidden;
    height: 50px;
  }

  &-city {
    display: block;
    font-weight: 500;
    font-size: 13px;
    margin-top: 5px;
    line-height: 1.2;
    color: #333338;
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
  }

  &-type {
    display: block;
    font-weight: 400;
    font-size: 13px;
    color: #55555A;
    line-height: 1.2;
  }

  &-time {
    margin-left: auto;
    display: block;
    font-weight: 400;
    font-size: 13px;
    color: #55555A;
    line-height: 1.2;
  }

  &-price-text {
    display: block;
    font-weight: 400;
    font-size: 13px;
    color: #55555A;
    margin-top: 5px;
    line-height: 1.2;

    & b {
      font-weight: 500;
      color: #55555A;

    }

  }

  &-type {
    display: block;
  }



}
