.h-text-input {
  border: 1px solid transparent;
  background: #F3F4F9;
  border-radius: 5px;
  color: #55555A;
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 12px 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.2s, border-color 0.2s;
  outline: none;

  &:focus {
    background-color: #ffffff;
    border-color: #D8E0E6;
  }

  &-error {
    border-color: #FF7188;
    background-color: #FFFFFF;
  }

  &::selection {}

}
