.h-label-area {

  margin-top: 15px;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  &-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 9px;
    color: #333338;
    display: block;

    &-required {

      &:after {
        content: '*';
        color: #FF7188;
        margin-left: 4px;
      }

    }

  }

  &-error {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 4px;
    color: #FF7188;
    display: block;
  }

}

.h-label-row {
  display: flex;
  column-gap: 20px;
  margin-top: 15px;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  & .h-label-area {
    margin-top: 0;
  }

}
