.h-table {
  width: 100%;
  background-color: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px #E6E9F2;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  &-bottom {
    height: 46px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #fff;
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: auto;
    border-top: 1px solid #E6E9F2;
    border-radius: 0 0 5px 5px;

    &-label {
      font-size: 13px;
      color: #55555A;
    }

  }

  &-table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;
    border-radius: 5px 5px 0 0;

    & thead {
      background-color: #F3F4F9;
      box-sizing: border-box;

      & th {
        padding: 0 12px 0 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #E6E9F2;
        height: 48px;

        & span {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #333338;
          text-align: left;
          display: block;
        }

      }

    }

    & tbody {

      & td {
        padding: 0 12px 0 16px;
        box-sizing: border-box;
        height: 48px;
        border-bottom: 1px solid #E6E9F2;

        & span {
          color: #55555A;
          font-size: 14px;
          display: block;
        }

      }

    }

  }

  &-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-icon {
    height: 30px;
    border-radius: 3px;
    display: flex;
    width: 30px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: rgba(145, 155, 182, 0.1);
    }

  }

  &.small {

    & .h-table-table tbody td span {
      font-size: 12px;
    }

    & .h-table-table thead th span {
      font-size: 12px;
    }

  }

}
