.header {
  height: 56px;
  min-height: 56px;
  background-color: #fff;
  border-bottom: 1px solid #E6E9F2;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-left: auto;
  }

  &-icon {
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;

    & svg {
      fill: #91A2B6;
    }

    &-num {
      position: absolute;
      top: 12px;
      z-index: 2;
      right: 12px;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      font-weight: 600;
      background-color: red;
      color: #FFFFFF;
      font-size: 7px;
    }

    &:hover {
      background-color: rgba(145, 155, 182, 0.1);
    }

  }

  &-datetime {
    width: 56px;
    height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: rgba(145, 155, 182, 0.1);
    }

    &-border {
      width: 3px;
      height: 42px;
      background-color: #4C87B9;
      border-radius: 1.5px;
    }

    &-value {
      margin-right: 10px;
    }

    &-day {
      display: block;
      color: #333338;
      font-size: 28px;
      line-height: 30px;
    }

    &-week {
      display: block;
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      color: #91A2B6;
      text-transform: capitalize;
    }

  }

  &-logo {

    &-image {
      height: 23px;
      display: block;
    }

  }

  &-search {
    background: rgb(230 233 242 / 0.5);
    width: 300px;
    height: 100%;
    display: block;
    margin-left: 70px;
    position: relative;

    &-top {
      position: relative;
      height: 100%;
    }

    &-submenu {
      position: absolute;
      top: 55px;
      left: 0;
      background: #FFFFFF;
      border: none;
      box-shadow: 0 10px 20px rgba(64, 72, 98, 0.2);
      border-radius: 0 0 5px 5px;
      padding: 0;
      margin: 0;
      list-style: none;
      min-width: 100%;
      max-height: 300px;
      z-index: 99999999;
      overflow-y: auto;

      &-element {
        padding: 11px 12px;
        transition: background-color 0.2s;
        color: #55555A;
        cursor: pointer;
        min-height: 61px;
        font-size: 14px;
        line-height: 1.4;
        box-sizing: border-box;
        border-bottom: 1px solid #E6E9F2;

        &:last-child {
          border-bottom-width: 0;
        }

        &:hover {
          background-color: #F3F4F9;
        }

        &-name {
          display: block;
          color: #55555A;
          font-size: 14px;
        }

        &-phone {
          display: block;
          font-size: 12px;
          font-weight: 600;
          color: #333338;
        }

      }

    }

    & input {
      width: 100%;
      height: 100%;
      font-size: 14px;
      box-sizing: border-box;
      border: 0;
      outline: none;
      background-color: transparent;
      color: #333338;
      font-family: 'Inter', sans-serif;
      padding: 0 56px 0 16px;

      &::placeholder {
        color: #97a3b0;
      }

    }

    &-icon {
      position: absolute;
      height: 56px;
      width: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;

      & svg {
        fill: #91A2B6;
      }

    }

  }

}

.header-user {
  position: relative;

  &-top {
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;

    &-icon {
      width: 40px;
      height: 40px;
      border-radius: 4px;
      background-color: #FFB400;
      display: flex;
      align-items: center;
      justify-content: center;

      &-label {
        font-size: 20px;
        color: #FFFFFF;
        font-weight: 500;
      }

    }

    &:hover {
      background-color: rgba(145, 155, 182, 0.1);
    }

  }

  &-submenu {
    position: absolute;
    top: 56px;
    right: 0;
    width: 300px;
    padding-top: 12px;

    &-inner {
      width: 100%;
      border-radius: 4px;
      background-color: #3761E9;
    }

    &-top {
      padding: 16px;
      box-sizing: border-box;
      box-shadow: 0 1px 0 0 rgba(255,255,255,0.1);
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 9px 20px;
      box-sizing: border-box;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }

      & svg {
        font-size: 24px;
        color: rgba(255,255,255,.5);
        margin-right: 14px;
      }

      &-label {
        font-size: 14px;
        color: rgba(255,255,255,.8);
      }

    }

    &-icon {

      width: 56px;
      height: 56px;
      border-radius: 4px;
      background-color: #FFB400;
      display: flex;
      align-items: center;
      justify-content: center;

      &-label {
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
      }

    }

    &-info {
      width: calc(100% - 56px);
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      box-sizing: border-box;

      &-title {
        font-size: 15px;
        font-weight: 500;
        color: #FFFFFF;
      }

      &-subtitle {
        color: #fff;
        opacity: 0.5;
        margin-top: 2px;
        font-size: 13px;
        display: block;
      }

    }

  }

}
