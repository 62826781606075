.h-modal {
  min-width: 600px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  position: relative;

  &-close {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    width: 46px;
    z-index: 100;
    box-sizing: border-box;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      background-color: rgba(145, 155, 182, 0.1);
    }

  }

  &-header {
    padding: 20px 24px;
    border-bottom: 1px solid #E6E9F2;

    &-element {
      font-size: 14px;
      line-height: 18px;
      margin-top: 9px;
      color: #333338;
      display: block;

      & b {
        font-weight: 500;
      }

      &:first-child {
        margin-top: 0;
      }

    }

  }

  &-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #E6E9F2;
    height: 46px;
    padding: 20px 24px;
    box-sizing: border-box;
  }

  &-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid #E6E9F2;
    padding: 20px 24px;
    box-sizing: border-box;
  }

  &-title {
    font-size: 16px;
    font-weight: 500;
  }

  &-content {
    padding: 20px 24px;
    box-sizing: border-box;
  }

}
