.schedules {

  width: 100%;

  &-top {
    width: 100%;
    display: flex;
    flex-direction: row;

    &-element {
      width: 14%;
      height: 40px;
      box-sizing: border-box;
      background-color: #FFFFFF;
      border: 1px solid #e7eaec;
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;

      &-label {
        box-sizing: border-box;
        font-size: 14px;
        font-weight: 500;
        color: #333338;
      }

      &-icon {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4C87B9;
        color: #FFFFFF;
        font-size: 13px;
      }

    }

  }


  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;

    &-col {
      width: 14%;
    }

  }

  &-element {
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border: 1px solid #e7eaec;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    cursor: pointer;
    flex-direction: column;

    &-icons {
      position: absolute;
      top: 4px;
      right: 4px;
      flex-direction: row;
      display: none;
    }

    &-time {
      box-sizing: border-box;
      font-size: 18px;
      color: #333338;
    }

    &-icon {
      width: 17px;
      border-radius: 4px;
      height: 17px;
      font-size: 10px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #E6E9F2;
      margin-left: 4px;

      &:hover {
        opacity: 7;
      }
    }

    &-dop {
      display: flex;
      flex-direction: row;
      margin-top: 2px;

      &-icon {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

    }

    &:hover {

      & .schedules-element-icons {
        display: flex;
      }

    }

  }

}
