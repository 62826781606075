.left-menu {
  height: 100vh;
  width: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  border-right: 1px solid #E6E9F2;
  padding-top: 56px;
  box-sizing: border-box;

  &-element {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 12px 12px 6px 12px;
    max-width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    border-bottom: 1px solid #E6E9F2;
    text-decoration: none;

    &-label {
      font-size: 9px;
      margin-top: 4px;
      line-height: 14px;
      color: #97A3B0;
      transition: color .2s, opacity .2s;
      word-wrap: break-word;
      display: block;
      font-weight: bold;
    }

    & svg {
      height: 20px;
      width: 20px;
      display: inline-block;
      background-size: contain;
      opacity: 0.4;
      transition: opacity .2s, filter 0.2s;
      fill: #97A3B0;
    }

    &.active, &:hover {

      & .left-menu-element-label {
        color: #000;
      }

      & svg {
        fill: #000000;
      }

    }

  }

}
