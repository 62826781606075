.container {
  box-sizing: border-box;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 0 0 2px rgba(0,0,0,.2);
}

.content {
  width: 100%;
  height: 100%;
  padding-left: 70px;
  box-sizing: border-box;
}

.content-inner {
  padding: 40px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-top-40 {
  margin-top: 40px;
}

.page {

  &-top {
    padding: 14px 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    background: #f8f9fd;
    border-bottom: 1px solid #E6E9F2;
    align-items: center;

    &-right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

  }

  &-content {
    padding: 20px 24px 24px;
  }

  &-title {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0;
    color: #333338;
  }

}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}
