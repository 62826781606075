.login-page {
  display: flex;
  flex-direction: row;

  &-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    top: -56px;
    position: relative;
  }

  &-right {
    width: 60%;
    background-color: #FFFFFF;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: -56px;
    position: relative;
  }

  &-form {
    min-width: 450px;
  }

  &-title {
    display: block;
    color: #333;
    font-weight: 400;
    font-size: 34px;
  }

  &-fields {
    margin-top: 30px;
  }

  &-subtitle {
    color: #646c9a;
    font-size: 14px;
    display: block;
    margin-top: 12px;
    position: relative;
    left: 2px;

    & a {
      text-decoration: none;
      color: #5867dd;
    }

  }

  &-submit {
    margin-top: 20px;
  }

  &-promo {
    max-width: 370px;
    margin: auto;
  }

  &-logo {
    width: 250px;
  }

  &-top {
    margin-bottom: auto;
    padding: 30px;
    box-sizing: border-box;
  }

  &-sub-logo {
    display: block;
    font-size: 18px;
    margin-top: 20px;
    line-height: 24px;
    color: #333;
    box-sizing: border-box;
  }

  &-bottom {
    margin-top: auto;
    padding: 30px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }

  &-copyright {
    margin-left: auto;
    font-size: 13px;
    color: #333333;

    & a {
      text-decoration: none;
      color: #5867dd;
    }

  }

}
