.h-tabs {
  display: flex;
  flex-direction: row;
  border: 1px solid #D6DBE5;
  height: 35px;
  border-radius: 4px;
}

.h-tab {
  border-left: 1px solid #D6DBE5;
  font-size: 12px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  cursor: pointer;
  align-items: center;

  &.active {
    background-color: #4C87B9;
    color: #FFFFFF;
    font-weight: bold;
  }

  &:first-child {
    border-left-width: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

}
