.h-select {

  position: relative;

  &-top {
    background: #F3F4F9;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    padding: 12px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: row;

    &.white {
      background-color: #FFFFFF;
    }

    &-opened {
      border-radius: 5px 5px 0 0;
    }

    &-label {
      font-size: 14px;
      overflow: hidden;
      color: #55555A;
      text-overflow: ellipsis;
    }

    &-arrow {
      margin-left: auto;
      width: 8px;
      height: 6px;
      transition: transform 0.2s, margin 0.2s;

      &-opened {
        transform: rotate(-180deg);
      }

    }

  }

  &-submenu {
    position: absolute;
    top: 40px;
    left: 0;
    background: #FFFFFF;
    border: none;
    box-shadow: 0 10px 20px rgba(64, 72, 98, 0.2);
    border-radius: 0 0 5px 5px;
    padding: 0;
    margin: 0;
    list-style: none;
    min-width: 100%;
    max-height: 180px;
    z-index: 99999999;
    overflow-y: auto;

    & li {
      padding: 11px 12px;
      transition: background-color 0.2s;
      color: #55555A;
      cursor: pointer;
      min-height: 41px;
      font-size: 14px;
      line-height: 1.4;
      box-sizing: border-box;

      &:hover {
        background-color: #F3F4F9;
      }

    }

  }

}
