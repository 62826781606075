.h-button {
  font-weight: 500;
  text-transform: none;
  padding: 9px 16px;
  box-shadow: none;
  letter-spacing: normal;
  cursor: pointer;
  font-size: 15px;
  border-radius: 0.3rem;
  user-select: none;
  transition:
          color 0.15s ease-in-out,
          background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out,
          box-shadow 0.15s ease-in-out,
          opacity 0.15s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #8493A5;
  background-color: #F3F4F9;
  border: 1px solid #F3F4F9;

  &.small {
    font-size: 13px;
  }

  &:hover {
    color: #4C87B9;
    background-color: #3761E91a;
    border-color: transparent;
  }

  &:disabled {
    opacity: .8;
  }

  &-primary {

    color: #ffffff;
    background-color: #4C87B9;
    border: 1px solid #4C87B9;

    &:hover {
      opacity: .8;
      background-color: #4C87B9;
      border: 1px solid #4C87B9;
      color: #FFFFFF;
    }

  }

}


@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  display: block;
  margin-right: 8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #FFFFFF;
  animation: spinner .6s linear infinite;
}
