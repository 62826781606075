.trips-top {
  display: flex;
  flex-direction: row;

  &-element {
    width: 10%;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border: 1px solid #e7eaec;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 6px;
    padding-bottom: 6px;

    &-icon {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4C87B9;
      color: #FFFFFF;
      font-size: 10px;
    }

    &-date {
      display: block;
      font-size: 11px;
      font-weight: bold;
      color: #333338;
    }

    &-trips-num {
      display: block;
      font-size: 10px;
      font-weight: 500;
      color: #333338;
    }

  }

}

.trips-content {
  display: flex;
  flex-direction: row;

  &-col {
    width: 10%;
  }

}

.trip {
  width: 100%;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #e7eaec;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 4px;
  padding-bottom: 4px;
  color: #FFFFFF;
  cursor: pointer;

  &-dop-icons {
    display: flex;
    flex-direction: row;
  }

  &-dop-icon {
    font-size: 18px;
    margin-top: 4px;
    margin-right: 2px;
    margin-left: 2px;
  }

  &-icons {
    position: absolute;
    right: 0;
    top: 0;
    flex-direction: column;
    display: none;
  }

  &-icon {
    width: 20px;
    height: 20px;
    font-size: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4C87B9;
    margin-left: 4px;

    &:nth-child(2) {
      margin-top: 7px;
    }

    &:hover {
      opacity: 7;
    }
  }

  &-time {
    font-size: 20px;
    font-weight: 500;
  }

  &-info {
    font-size: 12px;
    font-weight: 500;
  }

  &:hover {
    opacity: 7;

    & .trip-icons {
      display: flex;
    }

  }


}

.tickets-modal {
  width: 1100px;
  min-height: 600px;

  &-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    &-right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-info {
      margin-left: 20px;
      font-size: 12px;

      &-label {
        display: block;
      }

      &-value {
        display: block;
        font-weight: 500;
      }

    }

  }

  &-create-button {
    display: inline-block;
    margin-left: 20px;
    font-weight: bold;

    &:hover {
      background-color: #4C87B9;

      & .trip-icons {
        display: block;
      }

    }

  }

}

.create-ticket-form {
  display: flex;
  flex-direction: row;
  width: 800px;

  &-left {
    width: 50%;
    padding-right: 12px;
    box-sizing: border-box;
  }

  &-right {
    width: 50%;
    padding-left: 12px;
    box-sizing: border-box;
  }

  &-row {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    column-gap: 20px;

    & .h-label-area {
      margin-top: 0;
    }

  }

}
